import Vue from 'vue'
import Vuex from 'vuex'
import createVuexAlong from 'vuex-along'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    id:'',
  },
  getters: {
  },
  mutations: {
    getId(state,id){
      state.id=''
      state.id=id
    }
  },
  actions: {
  },
  modules: {
  },
  plugins:[createVuexAlong()]
})

export default store

