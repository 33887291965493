<template>
  <div @touchmove.prevent>
    <video autoplay loop muted id="myVideo" class="videoCss">
      <source src="../assets/video/mov.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "movie",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // moviePlay() {
    //   var vdo = document.getElementById("myVideo");
    //   vdo.play();
    // }
  }
};
</script>
<style lang="scss" scoped>
body,
html {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  background-color: #000 !important;
}
.logo {
  position: absolute;
  top: 9%;
  width: 220px;
  height: 80px;
  z-index: 99999;
}
.videoCss {
  position: absolute;
  width: 100%;
  min-width: 1550px;
  height: 100%;
  object-fit: fill;
}
@media screen and (min-width: 0px) and (max-width: 2560px) {
  .videoCss {
    object-fit: cover !important;
  }
}
.videoCss {
  aspect-ratio: 21/9;
  object-fit: contain;
}
</style>
