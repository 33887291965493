<template>
  <div>
    <!-- 头部 -->
    <div class="head">
      <img src="../assets/images/logo.png" @click="goHome">
      <div @click="goHome">首页</div>
      <div class="serve">
        <span @click="boxTag" style="display:inline-block">产品服务</span>
        <div class="listItem">
          <ul>
            <li
              v-for="(item,index) in headList"
              :key="index"
              :class="childrenId==index ? 'nactive' : 'nproserve'"
              @click="getDiv(item,index)"
            >{{item.name}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navbar",
  data() {
    return {
      // 导航栏
      childrenId: "",
      headList: [
        {
          name: "Rimdeep",
          url: "/rimdeep"
        },
        {
          name: "追影者",
          url: "/spider"
        },
        {
          name: "RimDeep",
          url: "/artificial"
        },
        {
          name: "玄灵神",
          url: "/god"
        },
        {
          name: "含光",
          url: "/light"
        },
        {
          name: "云追查",
          url: "/yzc"
        }
      ],
      liShow: false
    };
  },
  mounted() {
    this.getId();
    this.getDiv();
  },
  methods: {
    //跳转首页
    goHome() {
      this.$router.push("/");
    },
    // 获取选择Id
    getId() {
      this.childrenId = this.$store.state.id;
    },
    // 导航显示
    boxTag() {
      this.liShow = !this.liShow;
    },
    // 导航栏获取id
    getDiv(item, index) {
      if (index == 0) {
        this.$store.commit("getId", index);
        this.$router.push("/rimdeep");
      } else if (index == 1) {
        this.$store.commit("getId", index);
        this.$router.push("/spider");
      } else if (index == 2) {
        this.$store.commit("getId", index);
        this.$router.push("/artificial");
      } else if (index == 3) {
        this.$store.commit("getId", index);
        this.$router.push("/god");
      } else if (index == 4) {
        this.$store.commit("getId", index);
        this.$router.push("/light");
      } else if (index == 5) {
        this.$store.commit("getId", index);
        this.$router.push("/yzc");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.head {
  padding-left: 10px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  z-index: 222;
}
.head > img {
  width: 115px;
  height: 25px;
  margin: 12px 0 0 0 !important;
}
.head > div {
  padding: 0 20px;
}
.serve {
  position: relative;
  margin: 0 0;
}
.nactive {
  background-color: rgba(2, 224, 219, 0.1);
  font-weight: bold;
  font-size: 18px;
  padding-left: 10px;
}
.nproserve {
  margin: 0 0 0 15px;
}
.serve:hover .listItem {
  display: block;
}
.listItem {
  position: absolute;
  top: 100%;
  width: 110px;
  display: none;
  z-index: 999999;
  padding-bottom: 10px;
  background-color: rgba(2, 224, 219, 0.3);
}
.liShow {
  display: none;
}
.listItem > ul > li {
  height: 35px;
  line-height: 40px;
}
@media screen and (min-width: 0px) and (max-width: 320px) {
  .head > img {
    width: 120px;
    height: 30px;
    margin: 10px 0 0 0 !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .head {
    height: 80px;
    line-height: 80px;
    font-size: 24px;
    padding-left: 50px;
  }
  .head > img {
    width: 220px;
    height: 40px;
    margin: 20px 0 0 0 !important;
  }
}
@media screen and (min-width: 820px) and (max-width: 1280px) {
  .head {
    height: 75px;
    line-height: 75px;
    font-size: 20px;
    padding-left: 50px;
  }
  .head > img {
    width: 200px;
    height: 40px;
    margin: 17px 0 0 0 !important;
  }
}
@media screen and (min-width: 1024px) and (min-width: 1366px) {
  .head {
    height: 90px;
    line-height: 90px;
    font-size: 30px;
    padding-left: 50px;
  }
  .head > img {
    width: 230px;
    height: 50px;
    margin: 20px 0 0 0 !important;
  }
}
</style>
