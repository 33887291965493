<template>
  <div>
    <child></child>
  </div>
</template>
<script>
import child from '../../components/child'
export default {
  data() {
    return {};
  },
  components:{
    child
  }
};
</script>
<style scoped>
  
</style>