import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
// 引入公共样式
import './assets/css/global.css'
import './assets/css/common.scss'
import './assets/css/header.css'
// import './assets/css/navigation.css'
// 引入视频插件
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
