<template>
  <div>
    <child></child>
    <!-- <div class="ip"></div> -->
  </div>
</template>
<script>
import child from '../../components/child'
export default {
  data() {
    return {
    };
  },
  components:{
    child
  }
};
</script>
<style scoped>
  .ip{
    width:250px;
    height: 200px;
    position: absolute;
    right: 300px;
    top:500px;
    background-image: url('../../assets/images/imgs/ipImage.png');
    background-size: 250px 200px;
  }
</style>