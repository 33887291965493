<template>
  <div>
    <movie class="movieShow"></movie>
    <div class="main">
      <div class="left">
        <!-- 左上 -->
        <div class="leftTop" @click="goChild(5)">
          <p>云追查</p>
          <img src="../assets/images/ltop.png" class="item topItem">
        </div>
        <!-- 左中 -->
        <div class="leftMiddle" @click="goChild(4)">
          <p>含光</p>
          <img src="../assets/images/lmid.png" class="item middleItem">
        </div>
        <!-- 左下 -->
        <div class="leftBottom" @click="goChild(3)">
          <p>玄灵神</p>
          <img src="../assets/images/lbot.png" class="item bottomItem">
        </div>
      </div>
      <div class="right">
        <!-- 右上 -->
        <div class="rightTop" @click="goChild(0)">
          <p>RimDeep</p>
          <img src="../assets/images/rtop.png" class="item itemTop">
        </div>
        <!-- 右中 -->
        <div class="rightMiddle" @click="goChild(1)">
          <p>追影者</p>
          <img src="../assets/images/rmid.png" class="item itemMiddle" id="middleR">
        </div>
        <!-- 右下 -->
        <div class="rightBottom" @click="goChild(2)">
          <p>承影</p>
          <img src="../assets/images/rbot.png" class="item itemBottom" id="middleR">
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div style="background-color:black;">
      <navbar class="headIs"></navbar>
      <div class="modelItem">
        <div class="mitem" v-for="item in modelList" :key="item.ind" @click="goChild(item.ind)">
          <p>{{item.name}}</p>
        </div>
      </div>
      <!-- 底部 -->
      <div class="foot footIs">
        <div class="footList">
          <ul>
            <li>
              <img src="../assets/images/imgs/foot1.png">
              <span>联系我们</span>
            </li>
            <li>
              <img src="../assets/images/imgs/foot2.png">
              <span>加入我们</span>
            </li>
            <li class="download">
              <img src="../assets/images/imgs/foot3.png">
              <img src="../assets/images/code.png" class="code">
              <span>下载</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "../components/nav";
import movie from "../components/VideoPlayer";
export default {
  data() {
    return {
      modelList: [
        {
          ind: 0,
          name: "RimDeep",
          url: "/rimdeep"
        },
        {
          ind: 1,
          name: "追影者",
          url: "/spider"
        },
        {
          ind: 2,
          name: "承影",
          url: "/artificial"
        },
        {
          ind: 3,
          name: "玄灵神",
          url: "/god"
        },
        {
          ind: 4,
          name: "含光",
          url: "/light"
        },
        {
          ind: 5,
          name: "云追查",
          url: "/yzc"
        }
      ],
      docmWidth: document.documentElement.clientWidth, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      hidshow: false //显示或者隐藏footer,
    };
  },
  components: {
    movie,
    navbar
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        that.docmWidth = window.fullWidth;
      })();
    };
  },
  watch: {
    docmWidth(val) {
      // console.log(val)
    }
  },
  methods: {
    goChild(id) {
      if (id == 0) {
        this.$store.commit("getId", id);
        window.open("/rimdeep", "_blank");
      } else if (id == 1) {
        this.$store.commit("getId", id);
        window.open("/spider", "_blank");
      } else if (id == 2) {
        this.$store.commit("getId", id);
        window.open("/artificial", "_blank");
      } else if (id == 3) {
        this.$store.commit("getId", id);
        window.open("/god", "_blank");
      } else if (id == 4) {
        this.$store.commit("getId", id);
        window.open("/light", "_blank");
      } else if (id == 5) {
        this.$store.commit("getId", id);
        window.open("/yzc", "_blank");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
body {
  width: 100% !important;
  height: 100% !important;
}
.logo {
  position: absolute;
  top: 9%;
  width: 220px;
  height: 80px;
  left: 44.5%;
}
.movieShow {
  display: block;
}
.main {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  min-width: 1550px;
  // min-height: 1366px;
}

// 带鱼屏 21:9的宽高比
.main {
  aspect-ratio: 21/9;
  justify-content: center;
}
.caseNum {
  position: absolute;
  right: 40%;
  bottom: 8%;
  font-size: 16px;
  color: #fff;
}
.left,
.right {
  width: 50%;
  position: relative;
  aspect-ratio: 16/9;
}
.left,
.right {
  width: 40%;
  aspect-ratio: 21/9;
}

.item {
  position: absolute;
  width: 500px;
}
p {
  // display: none;
  position: absolute;
  font-size: 30px;
  z-index: 2;
  background-image: linear-gradient(#b2f9c0, #66ffff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bolder;
  cursor: pointer;
}

// 右上
.itemTop {
  top: 14%;
  right: 37%;
}
.rightTop > p {
  top: 22%;
  right: 52%;
}
.rightTop > p:hover ~ .itemTop {
  width: 540px;
}
.rightTop > p:hover {
  font-size: 35px;
  cursor: pointer;
}
.itemTop {
  -webkit-animation: topAni 1s linear 1;
  -moz-animation: topAni 1s linear 1;
  -o-animation: topAni 1s linear 1;
  -ms-animation: topAni 1s linear 1;
}
@keyframes topAni {
  from {
    top: 35%;
    right: 100%;
    width: 0;
    opacity: 0;
  }
  to {
    top: 14%;
    right: 37%;
    // width: 530px;
    opacity: 1;
  }
}
.rightTop > p {
  -webkit-animation: pAni 1s linear 1;
  -moz-animation: pAni 1s linear 1;
  -o-animation: pAni 1s linear 1;
  -ms-animation: pAni 1s linear 1;
}
@keyframes pAni {
  from {
    top: 35%;
    right: 100%;
    opacity: 0;
    font-size: 0;
  }
  to {
    top: 22%;
    right: 52%;
    opacity: 1;
    font-size: 30px;
  }
}
// 右中
.itemMiddle {
  top: 40%;
  right: 27%;
  width: 0;
}
.rightMiddle > p {
  top: 50%;
  right: 46%;
  font-size: 0;
}
.rightMiddle > p:hover ~ .itemMiddle {
  width: 530px !important;
}
.rightMiddle > p:hover {
  font-size: 35px !important;
  cursor: pointer;
}
.itemMiddle {
  -webkit-animation: itemMi 1s linear 1;
  -moz-animation: itemMi 1s linear 1;
  -o-animation: itemMi 1s linear 1;
  -ms-animation: itemMi 1s linear 1;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}
@keyframes itemMi {
  from {
    top: 50%;
    right: 100%;
    width: 0;
    opacity: 0;
  }
  to {
    top: 40%;
    right: 27%;
    width: 500px;
    opacity: 1;
  }
}
.rightMiddle > p {
  -webkit-animation: pMi 1s linear 1;
  -moz-animation: pMi 1s linear 1;
  -o-animation: pMi 1s linear 1;
  -ms-animation: pMi 1s linear 1;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  -ms-animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}
@keyframes pMi {
  from {
    top: 50%;
    right: 100%;
    opacity: 0;
    font-size: 0;
  }
  to {
    top: 50%;
    right: 46%;
    opacity: 1;
    font-size: 30px;
  }
}
// 右下
.itemBottom {
  bottom: 5%;
  right: 35%;
  width: 0;
}
.rightBottom > p {
  bottom: 15%;
  right: 58%;
  font-size: 0;
}
.rightBottom > p:hover ~ .itemBottom {
  width: 540px !important;
}
.rightBottom > p:hover {
  font-size: 35px !important;
  cursor: pointer;
}
.itemBottom {
  -webkit-animation: itemBo 1s linear 1;
  -moz-animation: itemBo 1s linear 1;
  -o-animation: itemBo 1s linear 1;
  -ms-animation: itemBo 1s linear 1;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  -ms-animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}
@keyframes itemBo {
  from {
    bottom: 50%;
    right: 100%;
    width: 0;
    opacity: 0;
  }
  to {
    bottom: 5%;
    right: 35%;
    width: 500px;
    opacity: 1;
  }
}
.rightBottom > p {
  -webkit-animation: pBo 1s linear 1;
  -moz-animation: pBo 1s linear 1;
  -o-animation: pBo 1s linear 1;
  -ms-animation: pBo 1s linear 1;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  -ms-animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}
@keyframes pBo {
  from {
    bottom: 50%;
    right: 100%;
    opacity: 0;
    font-size: 0;
  }
  to {
    bottom: 15%;
    right: 58%;
    opacity: 1;
    font-size: 30px;
  }
}
// 左下
.bottomItem {
  bottom: 5%;
  left: 34%;
  width: 0;
}
.leftBottom > p {
  bottom: 15%;
  left: 54%;
  font-size: 0;
}
.leftBottom > p:hover ~ .bottomItem {
  width: 540px !important;
}
.leftBottom > p:hover {
  // bottom: 18% !important;
  // left: 55% !important;
  font-size: 35px !important;
  cursor: pointer;
}
.bottomItem {
  -webkit-animation: itmleftBo 1s linear 1;
  -moz-animation: itmleftBo 1s linear 1;
  -o-animation: itmleftBo 1s linear 1;
  -ms-animation: itmleftBo 1s linear 1;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  -ms-animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}
@keyframes itmleftBo {
  from {
    bottom: 50%;
    left: 100%;
    width: 0;
    opacity: 0;
  }
  to {
    bottom: 5%;
    left: 34%;
    width: 500px;
    opacity: 1;
  }
}
.leftBottom > p {
  -webkit-animation: pleftBo 1s linear 1;
  -moz-animation: pleftBo 1s linear 1;
  -o-animation: pleftBo 1s linear 1;
  -ms-animation: pleftBo 1s linear 1;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  -ms-animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}

@keyframes pleftBo {
  from {
    bottom: 50%;
    left: 100%;
    font-size: 0;
    opacity: 0;
  }
  to {
    bottom: 15%;
    left: 54%;
    font-size: 30px;
    opacity: 1;
  }
}
// 左中
.middleItem {
  top: 40%;
  left: 26%;
  width: 0;
}
.leftMiddle > p {
  top: 50%;
  left: 47%;
  font-size: 0;
}
.leftMiddle > p:hover ~ .middleItem {
  width: 530px !important;
}
.leftMiddle > p:hover {
  font-size: 35px !important;
  cursor: pointer;
}
.middleItem {
  -webkit-animation: itmleftMi 1s linear 1;
  -moz-animation: itmleftMi 1s linear 1;
  -o-animation: itmleftMi 1s linear 1;
  -ms-animation: itmleftMi 1s linear 1;
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  -ms-animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}
@keyframes itmleftMi {
  from {
    top: 50%;
    left: 100%;
    width: 0;
    opacity: 0;
  }
  to {
    top: 40%;
    left: 26%;
    width: 490px;
    opacity: 1;
  }
}
.leftMiddle > p {
  -webkit-animation: pleftMi 1s linear 1;
  -moz-animation: pleftMi 1s linear 1;
  -o-animation: pleftMi 1s linear 1;
  -ms-animation: pleftMi 1s linear 1;
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  -ms-animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}
@keyframes pleftMi {
  from {
    top: 50%;
    left: 100%;
    font-size: 0;
    opacity: 0;
  }
  to {
    top: 50%;
    left: 47%;
    font-size: 30px;
    opacity: 1;
  }
}
// 左上
.topItem {
  top: 14%;
  left: 34%;
  width: 0;
}
.leftTop > p {
  top: 23%;
  left: 53%;
  font-size: 0;
}
.leftTop > p:hover ~ .topItem {
  width: 540px !important;
}
.leftTop > p:hover {
  font-size: 35px !important;
  cursor: pointer;
}
.topItem {
  -webkit-animation: itmleftTo 1s linear 1;
  -moz-animation: itmleftTo 1s linear 1;
  -o-animation: itmleftTo 1s linear 1;
  -ms-animation: itmleftTo 1s linear 1;
  -webkit-animation-delay: 5s;
  -moz-animation-delay: 5s;
  -o-animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}
@keyframes itmleftTo {
  from {
    top: 50%;
    left: 100%;
    width: 0;
    opacity: 0;
  }
  to {
    top: 14%;
    left: 34%;
    width: 520px;
    opacity: 1;
  }
}
.leftTop > p {
  -webkit-animation: pleftTo 1s linear 1;
  -moz-animation: pleftTo 1s linear 1;
  -o-animation: pleftTo 1s linear 1;
  -ms-animation: pleftTo 1s linear 1;
  -webkit-animation-delay: 5s;
  -moz-animation-delay: 5s;
  -o-animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  -ms-animation-fill-mode: forwards;
}
@keyframes pleftTo {
  from {
    top: 50%;
    left: 100%;
    font-size: 0;
    opacity: 0;
  }
  to {
    top: 23%;
    left: 53%;
    font-size: 30px;
    opacity: 1;
  }
}
@media screen and (min-width: 1370px) and (max-width: 1549px) {
  .item {
    width: 380px;
  }
   .left,
.right {
  width: 50%;
  position: relative;
  aspect-ratio: 16/9;
}
  p {
    font-size: 26px;
  }
  //  右上
  .itemTop {
    top: 22%;
    right: 37%;
  }
  .rightTop > p {
    top: 30%;
    right: 55%;
  }
  .rightTop > p:hover ~ .itemTop {
    width: 400px !important;
  }
  .rightTop > p:hover {
    font-size: 32px;
    cursor: pointer;
  }
  @keyframes topAni {
    from {
      top: 35%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 22%;
      right: 37%;
      width: 400px;
      opacity: 1;
    }
  }
  @keyframes pAni {
    from {
      top: 35%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      top: 30% !important;
      right: 55%;
      opacity: 1;
      font-size: 32px;
    }
  }
  // 右中
  .itemMiddle {
    top: 43%;
    right: 27%;
    width: 0;
  }
  .rightMiddle > p {
    top: 50%;
    right: 46%;
    font-size: 0;
  }
  .rightMiddle > p:hover ~ .itemMiddle {
    width: 400px !important;
  }
  .rightMiddle > p:hover {
    font-size: 32px !important;
    cursor: pointer;
  }
  @keyframes itemMi {
    from {
      top: 50%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 43%;
      right: 27%;
      width: 380px;
      opacity: 1;
    }
  }
  @keyframes pMi {
    from {
      top: 50%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      top: 50%;
      right: 46%;
      opacity: 1;
      font-size: 30px;
    }
  }
  // 右下
  .itemBottom {
    bottom: 14%;
    right: 33%;
    width: 0;
  }
  .rightBottom > p {
    bottom: 22%;
    right: 55%;
    font-size: 0;
  }
  .rightBottom > p:hover ~ .itemBottom {
    width: 420px !important;
  }
  .rightBottom > p:hover {
    font-size: 28px !important;
    cursor: pointer;
  }
  @keyframes itemBo {
    from {
      bottom: 30%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      bottom: 14%;
      right: 33%;
      width: 400px;
      opacity: 1;
    }
  }
  @keyframes pBo {
    from {
      bottom: 30%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      bottom: 22%;
      right: 55%;
      opacity: 1;
      font-size: 24px;
    }
  }
  // 左下
  .bottomItem {
    bottom: 14%;
    right: 30%;
    width: 0;
  }
  .leftBottom > p:hover ~ .bottomItem {
    width: 400px !important;
  }
  .leftBottom > p {
    bottom: 21%;
    left: 50%;
    font-size: 0;
  }
  @keyframes itmleftBo {
    from {
      bottom: 30%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      bottom: 14%;
      left: 30%;
      width: 400px;
      opacity: 1;
    }
  }
  @keyframes pleftBo {
    from {
      bottom: 30%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      bottom: 21%;
      left: 50%;
      font-size: 30px;
      opacity: 1;
    }
  }
  // 左中
  .middleItem {
    top: 43%;
    left: 26%;
    width: 0;
  }
  .leftMiddle > p {
    top: 50%;
    left: 47%;
    font-size: 0;
  }
  .leftMiddle > p:hover ~ .middleItem {
    width: 400px !important;
  }
  .leftMiddle > p:hover {
    // left: 48% !important;
    font-size: 30px !important;
  }
  @keyframes itmleftMi {
    from {
      top: 43%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 43%;
      left: 26%;
      width: 400px;
      opacity: 1;
    }
  }
  @keyframes pleftMi {
    from {
      top: 43%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      top: 50%;
      left: 47%;
      font-size: 30px;
      opacity: 1;
    }
  }
  // 左上
  .topItem {
    top: 22%;
    left: 34%;
    width: 0;
  }
  .leftTop > p {
    top: 28%;
    left: 51%;
    font-size: 0;
  }
  .leftTop > p:hover ~ .topItem {
    width: 400px !important;
  }
  .leftTop > p:hover {
    top: 28% !important;
    font-size: 34px !important;
  }
  @keyframes itmleftTo {
    from {
      top: 35%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 22%;
      left: 34%;
      width: 400px;
      opacity: 1;
    }
  }
  @keyframes pleftTo {
    from {
      top: 35%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      top: 28%;
      left: 51%;
      font-size: 30px;
      opacity: 1;
    }
  }
}
@media screen and (min-width: 1368px) and (max-width: 1549px) {
  .item {
    width: 450px;
  }
   .left,
.right {
  width: 50%;
  position: relative;
  aspect-ratio: 16/9;
}
  .logo {
    position: absolute;
    top: 15%;
    width: 200px;
    height: 70px;
    left: 43.5%;
  }
  .caseNum {
    bottom: 13%;
    right: 39%;
  }
}
@media screen and (min-width: 1550px) and (max-width: 2048px) {
  .item {
    width: 450px;
  }
   .left,
.right {
  width: 50%;
  position: relative;
}
  .logo {
    position: absolute;
    top: 17%;
    width: 200px;
    height: 70px;
    left: 44.5%;
  }
  //  右上
  .itemTop {
    top: 20%;
    right: 37%;
  }
  .rightTop > p {
    top: 27%;
    right: 49%;
  }
  .rightTop > p:hover ~ .itemTop {
    width: 490px !important;
  }
  .rightTop > p:hover {
    font-size: 35px;
    cursor: pointer;
  }
  @keyframes topAni {
    from {
      top: 35%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 20%;
      right: 37%;
      width: 450px;
      opacity: 1;
    }
  }
  @keyframes pAni {
    from {
      top: 35%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      top: 27% !important;
      right: 49%;
      opacity: 1;
      font-size: 30px;
    }
  }
  // 右中
  .itemMiddle {
    top: 43%;
    right: 27%;
    width: 0;
  }
  .rightMiddle > p {
    top: 51%;
    right: 45%;
    font-size: 0;
  }
  .rightMiddle > p:hover ~ .itemMiddle {
    width: 480px !important;
  }
  .rightMiddle > p:hover {
    font-size: 35px !important;
    cursor: pointer;
  }
  @keyframes itemMi {
    from {
      top: 50%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 43%;
      right: 27%;
      width: 440px;
      opacity: 1;
    }
  }
  @keyframes pMi {
    from {
      top: 50%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      top: 51%;
      right: 45%;
      opacity: 1;
      font-size: 30px;
    }
  }
  // 右下
  .itemBottom {
    bottom: 9%;
    right: 33%;
    width: 0;
  }
  .rightBottom > p {
    bottom: 18%;
    right: 55%;
    font-size: 0;
  }
  .rightBottom > p:hover ~ .itemBottom {
    width: 490px !important;
  }
  .rightBottom > p:hover {
    font-size: 28px !important;
    cursor: pointer;
  }
  @keyframes itemBo {
    from {
      bottom: 30%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      bottom: 9%;
      right: 33%;
      width: 470px;
      opacity: 1;
    }
  }
  @keyframes pBo {
    from {
      bottom: 30%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      bottom: 18%;
      right: 55%;
      opacity: 1;
      font-size: 25px;
    }
  }
  // 左下
  .bottomItem {
    bottom: 9%;
    right: 30%;
    width: 0;
  }
  .leftBottom > p:hover ~ .bottomItem {
    width: 490px !important;
  }
  .leftBottom > p {
    bottom: 17%;
    left:48%;
    font-size: 0;
  }
  @keyframes itmleftBo {
    from {
      bottom: 30%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      bottom: 9%;
      left: 30%;
      width: 470px;
      opacity: 1;
    }
  }
  @keyframes pleftBo {
    from {
      bottom: 30%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      bottom: 17%;
      left: 48%;
      font-size: 30px;
      opacity: 1;
    }
  }
  // 左中
  .middleItem {
    top: 43%;
    left: 26%;
    width: 0;
  }
  .leftMiddle > p {
    top: 51%;
    left: 47%;
    font-size: 0;
  }
  .leftMiddle > p:hover ~ .middleItem {
    width: 480px !important;
  }
  .leftMiddle > p:hover {
    // left: 48% !important;
    font-size: 30px !important;
  }
  @keyframes itmleftMi {
    from {
      top: 43%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 43%;
      left: 26%;
      width: 450px;
      opacity: 1;
    }
  }
  @keyframes pleftMi {
    from {
      top: 43%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      top: 51%;
      left: 47%;
      font-size: 30px;
      opacity: 1;
    }
  }
  // 左上
  .topItem {
    top: 20%;
    left: 34%;
    width: 0;
  }
  .leftTop > p {
    top: 27%;
    left: 51%;
    font-size: 0;
  }
  .leftTop > p:hover ~ .topItem {
    width: 490px !important;
  }
  .leftTop > p:hover {
    top: 28% !important;
    font-size: 34px !important;
  }
  @keyframes itmleftTo {
    from {
      top: 35%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 20%;
      left: 34%;
      width: 450px;
      opacity: 1;
    }
  }
  @keyframes pleftTo {
    from {
      top: 35%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      top: 27%;
      left: 51%;
      font-size: 30px;
      opacity: 1;
    }
  }
}
.footIs {
  display: none;
}
.headIs {
  display: none;
}
.mitem > p {
  display: none;
}
.modelItem {
  display: none;
}
@media screen and (min-width: 2049px) and (max-width: 2560px) {
  .caseNum {
    bottom: 8%;
  }
  .left,
.right {
  width: 50%;
  position: relative;
  aspect-ratio: 16/9;
}
  .logo {
    position: absolute;
    top: 9%;
    width: 220px;
    height: 80px;
    left: 44%;
  }
  // 右中
  .rightMiddle > p {
    top: 49%;
    right: 46%;
    font-size: 0;
  }
  .rightMiddle > p {
    -webkit-animation: pMi 1s linear 1;
    -moz-animation: pMi 1s linear 1;
    -o-animation: pMi 1s linear 1;
    -ms-animation: pMi 1s linear 1;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
  }
  @keyframes pMi {
    from {
      top: 50%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      top: 49%;
      right: 46%;
      opacity: 1;
      font-size: 30px;
    }
  }
  // 右下
  .itemBottom {
    bottom: 10%;
    right: 38%;
    width: 0;
  }
  .rightBottom > p {
    bottom: 18%;
    right: 60%;
    font-size: 0;
  }
  .rightBottom > p:hover ~ .itemBottom {
    width: 490px !important;
  }
  .rightBottom > p:hover {
    font-size: 28px !important;
    cursor: pointer;
  }
  @keyframes itemBo {
    from {
      bottom: 30%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      bottom: 10%;
      right: 38%;
      width: 470px;
      opacity: 1;
    }
  }
  @keyframes pBo {
    from {
      bottom: 30%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      bottom: 18%;
      right: 60%;
      opacity: 1;
      font-size: 25px;
    }
  }
  // 左下
  .bottomItem {
    bottom: 10%;
    right: 36%;
    width: 0;
  }
  .leftBottom > p:hover ~ .bottomItem {
    width: 490px !important;
  }
  .leftBottom > p {
    bottom: 18%;
    left: 53%;
    font-size: 0;
  }
  @keyframes itmleftBo {
    from {
      bottom: 30%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      bottom: 10%;
      left: 36%;
      width: 470px;
      opacity: 1;
    }
  }
  @keyframes pleftBo {
    from {
      bottom: 30%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      bottom: 18%;
      left: 53%;
      font-size: 30px;
      opacity: 1;
    }
  }
  // 左中
  .leftMiddle > p {
    top: 49%;
    left: 47%;
    font-size: 0;
  }
  .leftMiddle > p:hover ~ .middleItem {
    width: 530px !important;
  }
  .leftMiddle > p:hover {
    font-size: 35px !important;
    cursor: pointer;
  }
  .leftMiddle > p {
    -webkit-animation: pleftMi 1s linear 1;
    -moz-animation: pleftMi 1s linear 1;
    -o-animation: pleftMi 1s linear 1;
    -ms-animation: pleftMi 1s linear 1;
    -webkit-animation-delay: 4s;
    -moz-animation-delay: 4s;
    -o-animation-delay: 4s;
    -ms-animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
  }
  @keyframes pleftMi {
    from {
      top: 50%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      top: 49%;
      left: 47%;
      font-size: 30px;
      opacity: 1;
    }
  }
  // 左上
  .leftTop > p {
    top: 35%;
    left: 100%;
    font-size: 0;
  }
  @keyframes pleftTo {
    from {
      top: 50%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      top: 22%;
      left: 51%;
      font-size: 30px;
      opacity: 1;
    }
  }
}
@media screen and (min-width: 2050px) and (max-width: 2568px) {
  .caseNum {
    bottom: 8%;
  }
  .logo {
    position: absolute;
    top: 9%;
    width: 220px;
    height: 80px;
    left: 44%;
  }
  .left,
.right {
  width: 50%;
  position: relative;
  aspect-ratio: 16/9;
}
  // 右中
  .rightMiddle > p {
    top: 49%;
    right: 46%;
    font-size: 0;
  }
  .rightMiddle > p {
    -webkit-animation: pMi 1s linear 1;
    -moz-animation: pMi 1s linear 1;
    -o-animation: pMi 1s linear 1;
    -ms-animation: pMi 1s linear 1;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    -ms-animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
  }
  @keyframes pMi {
    from {
      top: 50%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      top: 49%;
      right: 46%;
      opacity: 1;
      font-size: 30px;
    }
  }
  // 右下
  .itemBottom {
    bottom: 10%;
    right: 38%;
    width: 0;
  }
  .rightBottom > p {
    bottom: 18%;
    right: 58%;
    font-size: 0;
  }
  .rightBottom > p:hover ~ .itemBottom {
    width: 490px !important;
  }
  .rightBottom > p:hover {
    font-size: 28px !important;
    cursor: pointer;
  }
  @keyframes itemBo {
    from {
      bottom: 30%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      bottom: 10%;
      right: 38%;
      width: 470px;
      opacity: 1;
    }
  }
  @keyframes pBo {
    from {
      bottom: 30%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      bottom: 18%;
      right: 58%;
      opacity: 1;
      font-size: 25px;
    }
  }
  // 左下
  .bottomItem {
    bottom: 10%;
    right: 36%;
    width: 0;
  }
  .leftBottom > p:hover ~ .bottomItem {
    width: 490px !important;
  }
  .leftBottom > p {
    bottom: 18%;
    left: 53%;
    font-size: 0;
  }
  @keyframes itmleftBo {
    from {
      bottom: 30%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      bottom: 10%;
      left: 36%;
      width: 470px;
      opacity: 1;
    }
  }
  @keyframes pleftBo {
    from {
      bottom: 30%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      bottom: 18%;
      left: 53%;
      font-size: 30px;
      opacity: 1;
    }
  }
  // 左中
  .leftMiddle > p {
    top: 49%;
    left: 47%;
    font-size: 0;
  }
  .leftMiddle > p:hover ~ .middleItem {
    width: 530px !important;
  }
  .leftMiddle > p:hover {
    font-size: 35px !important;
    cursor: pointer;
  }
  .leftMiddle > p {
    -webkit-animation: pleftMi 1s linear 1;
    -moz-animation: pleftMi 1s linear 1;
    -o-animation: pleftMi 1s linear 1;
    -ms-animation: pleftMi 1s linear 1;
    -webkit-animation-delay: 4s;
    -moz-animation-delay: 4s;
    -o-animation-delay: 4s;
    -ms-animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
  }
  @keyframes pleftMi {
    from {
      top: 50%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      top: 49%;
      left: 47%;
      font-size: 30px;
      opacity: 1;
    }
  }
  // 左上
  .leftTop > p {
    top: 35%;
    left: 100%;
    font-size: 0;
  }
  @keyframes pleftTo {
    from {
      top: 50%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      top: 22%;
      left: 51%;
      font-size: 30px;
      opacity: 1;
    }
  }
}
@media screen and (min-width: 2569px) {
  .item {
    width: 45vh;
  }
  p {
    font-size: 3vh;
  }
  //  右上
  .itemTop {
    top: 22%;
    right: 37%;
  }
  .rightTop > p {
    top: 29%;
    right: 50%;
  }
  .rightTop > p:hover ~ .itemTop {
    width: 46vh !important;
  }
  .rightTop > p:hover {
    font-size: 4vh;
    cursor: pointer;
  }
  @keyframes topAni {
    from {
      top: 35%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 22%;
      right: 37%;
      opacity: 1;
    }
  }
  @keyframes pAni {
    from {
      top: 35%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      top: 30% !important;
      right: 50%;
      opacity: 1;
      font-size: 32px;
    }
  }
  // 右中
  .itemMiddle {
    top: 43%;
    right: 27%;
    width: 0;
  }
  .rightMiddle > p {
    top: 50%;
    right: 46%;
    font-size: 0;
  }
  .rightMiddle > p:hover ~ .itemMiddle {
    width: 400px !important;
  }
  .rightMiddle > p:hover {
    font-size: 32px !important;
    cursor: pointer;
  }
  @keyframes itemMi {
    from {
      top: 50%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 43%;
      right: 27%;
      width: 45vh;
      opacity: 1;
    }
  }
  @keyframes pMi {
    from {
      top: 50%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      top: 51%;
      right: 46%;
      opacity: 1;
      font-size: 3vh;
    }
  }
  // 右下
  .itemBottom {
    bottom: 14%;
    right: 33%;
    width: 0;
  }
  .rightBottom > p {
    bottom: 22%;
    right: 55%;
    font-size: 0;
  }
  .rightBottom > p:hover ~ .itemBottom {
    width: 46vh !important;
  }
  .rightBottom > p:hover {
    font-size: 4vh !important;
    cursor: pointer;
  }
  @keyframes itemBo {
    from {
      bottom: 30%;
      right: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      bottom: 14%;
      right: 33%;
      width: 46vh;
      opacity: 1;
    }
  }
  @keyframes pBo {
    from {
      bottom: 30%;
      right: 100%;
      opacity: 0;
      font-size: 0;
    }
    to {
      bottom: 22%;
      right: 55%;
      opacity: 1;
      font-size: 3vh;
    }
  }
  // 左下
  .bottomItem {
    bottom: 14%;
    right: 30%;
    width: 0;
  }
  .leftBottom > p:hover ~ .bottomItem {
    width: 46vh !important;
  }
  .leftBottom > p {
    bottom: 21%;
    left: 50%;
    font-size: 0;
  }
  @keyframes itmleftBo {
    from {
      bottom: 30%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      bottom: 14%;
      left: 30%;
      width: 45vh;
      opacity: 1;
    }
  }
  @keyframes pleftBo {
    from {
      bottom: 30%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      bottom: 21%;
      left: 50%;
      font-size: 3vh;
      opacity: 1;
    }
  }
  // 左中
  .middleItem {
    top: 43%;
    left: 26%;
    width: 0;
  }
  .leftMiddle > p {
    top: 50%;
    left: 47%;
    font-size: 0;
  }
  .leftMiddle > p:hover ~ .middleItem {
    width: 46vh !important;
  }
  .leftMiddle > p:hover {
    // left: 48% !important;
    font-size: 4vh !important;
  }
  @keyframes itmleftMi {
    from {
      top: 43%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 43%;
      left: 26%;
      width: 45vh;
      opacity: 1;
    }
  }
  @keyframes pleftMi {
    from {
      top: 43%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      top: 50%;
      left: 47%;
      font-size: 3vh;
      opacity: 1;
    }
  }
  // 左上
  .topItem {
    top: 22%;
    left: 34%;
    width: 0;
  }
  .leftTop > p {
    top: 28%;
    left: 51%;
    font-size: 0;
  }
  .leftTop > p:hover ~ .topItem {
    width: 46vh !important;
  }
  .leftTop > p:hover {
    top: 28% !important;
    font-size: 4vh !important;
  }
  @keyframes itmleftTo {
    from {
      top: 35%;
      left: 100%;
      width: 0;
      opacity: 0;
    }
    to {
      top: 22%;
      left: 34%;
      width: 45vh;
      opacity: 1;
    }
  }
  @keyframes pleftTo {
    from {
      top: 35%;
      left: 100%;
      font-size: 0;
      opacity: 0;
    }
    to {
      top: 28%;
      left: 51%;
      font-size: 3vh;
      opacity: 1;
    }
  }
}
// 手机端页面
@media screen and (min-width: 0) and (max-width: 1368px) {
  .headIs {
    display: block;
  }
  .main {
    display: none;
  }
  p {
    display: none;
  }
  .movieShow {
    display: none;
  }
  .modelItem {
    display: block;
    padding-top: 15%;
  }

  .mitem {
    position: relative;
    width: 307px;
    height: 166px;
    background-image: url("../assets/images/mobel.png");
    background-size: 300px 166px;
  }
  .mitem > p {
    position: absolute;
    top: 64px;
    left: 86px;
    display: block;
    font-size: 22px;
    z-index: 2;
    background-image: linear-gradient(#b2f9c0, #66ffff);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -ms-background-clip: text;
    -o-background-clip: text;
    color: transparent;
    font-weight: bolder;
  }
  /*底部*/
  .foot {
    display: block;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 200px;
    background-image: url("../assets/images/imgs/fbg.png");
    background-position: top;
    background-repeat: round;
  }
  .footList {
    position: absolute;
    left: 23%;
    bottom: 70px;
  }
  .footList > ul {
    display: flex;
  }
  .footList > ul > li {
    margin: 0 10px;
  }
  .footList > ul > li > img {
    width: 50px;
    vertical-align: middle;
  }
  .footList > ul > li > span {
    display: block;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
    color: #fff;
  }
  .footP {
    position: absolute;
    left: 12%;
    bottom: 40px;
    font-size: 12px;
    color: #9f9f9f;
  }
  .download {
    position: relative;
  }
  .download:hover .code {
    display: block;
  }
  .code {
    display: none;
    position: absolute;
    top: -50px;
    left: 50px;
    width: 60px !important;
    height: 60px;
  }
}
@media screen and (min-width: 0px) and (max-width: 320px) {
  .modelItem {
    padding: 0 4% 0 6%;
    padding-top: 15%;
  }
  .mitem > p {
    left: 84px;
  }
  .mitem {
    width: 286px;
    height: 166px;
    background-size: 286px 166px;
  }
  .footList {
    left: 18%;
  }
  .footP {
    left: 3%;
  }
}
@media screen and (min-width: 321px) and (max-width: 374px) {
  .modelItem {
    padding: 0 4%;
  }
  .footList {
    left: 18%;
  }
  .footP {
    left: 5%;
  }
}
@media screen and (min-width: 360px) and (max-width: 740px) {
  .modelItem {
    padding: 15% 10% 0 10%;
  }
  .footP {
    left: 10%;
  }
}
@media screen and (min-width: 375px) and (max-width: 412px) {
  .modelItem {
    display: block;
    padding: 15% 8% 0 12%;
  }
  .mitem > p {
    top: 38%;
    left: 30%;
  }
  .footP {
    left: 10%;
  }
}
@media screen and (min-width: 414px) and (max-width: 767px) {
  .modelItem {
    padding: 15% 5% 0 10%;
  }
  .mitem {
    width: 356px;
    height: 194px;
    background-size: 356px 210px;
  }
  .mitem > p {
    top: 14%;
    left: 8%;
    font-size: 24px;
  }
  .footP {
    left: 14%;
  }
}
@media screen and (min-width: 820px) and (max-width: 1180px) {
  .foot {
    height: 540px;
  }
  .footList {
    left: 20%;
    bottom: 120px;
  }
  .footList > ul > li {
    margin: 0 40px;
  }
  .footList > ul > li > img {
    width: 65px;
  }
  .footList > ul > li > span {
    font-size: 20px;
  }
  .footP {
    left: 21%;
    bottom: 70px;
    font-size: 18px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .modelItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .mitem > p {
    top: 38%;
    left: 29%;
  }
  .foot {
    height: 410px;
  }
  .footList {
    left: 20%;
    bottom: 120px;
  }
  .footList > ul > li {
    margin: 0 40px;
  }
  .footList > ul > li > img {
    width: 65px;
  }
  .footList > ul > li > span {
    font-size: 20px;
  }
  .footP {
    left: 21%;
    bottom: 70px;
    font-size: 18px;
  }
  .code {
    width: 100px !important;
    height: 100px;
    left: 70px;
    top: -50px;
  }
}
@media screen and (min-width: 820px) and (max-width: 1180px) {
  .modelItem {
    padding-top: 18%;
  }
  .foot {
    height: 540px;
  }
  .footList {
    left: 20%;
    bottom: 120px;
  }
  .footList > ul > li {
    margin: 0 40px;
  }
  .footList > ul > li > img {
    width: 65px;
  }
  .footList > ul > li > span {
    font-size: 20px;
  }
  .footP {
    left: 21%;
    bottom: 70px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1368px) {
  .modelItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 8%;
  }
  .mitem {
    width: 350px;
    height: 220px;
    background-size: 350px 200px;
  }
  .mitem > p {
    top: 34%;
    left: 30%;
    font-size: 26px;
  }
  .foot {
    height: 50vh;
  }
  .footList {
    left: 37%;
    bottom: 30%;
  }
  .footList > ul > li {
    margin: 0 20px;
  }
  .footList > ul > li > img {
    width: 80px;
  }
  .footList > ul > li > span {
    font-size: 20px;
  }
  .footP {
    left: 33%;
    bottom: 13%;
    font-size: 18px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1368px) {
  .modelItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 8% 10% 2%;
  }
  .footList {
    left: 30%;
  }
  .footP {
    left: 27%;
  }
  .code {
    width: 100px !important;
    height: 100px;
    left: 90px;
    top: -75px;
  }
  .foot {
    height: 47vh;
  }
  .footList {
    left: 37%;
    bottom: 30%;
  }
  .footList > ul > li {
    margin: 0 20px;
  }
  .footList > ul > li > img {
    width: 80px;
  }
  .footList > ul > li > span {
    font-size: 20px;
  }
  .footP {
    left: 33%;
    bottom: 13%;
    font-size: 18px;
  }
  @media screen and (min-width: 1024px) and (min-height: 1366px) {
    .modelItem {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding-top: 13%;
    }
    .mitem > p {
      left: 120px;
      top: 80px;
      font-size: 35px;
    }
    .mitem {
      width: 440px;
      height: 240px;
      background-size: 440px 240px;
    }
    .foot {
      height: 485px;
    }
    .footP {
      left: 30%;
    }
  }
}
</style>
