<template>
  <div class="main">
    <!-- 头部 -->
    <div class="headIs">
      <div class="head">
        <img src="../assets/images/logo.png" @click="goHome">
        <div @click="goHome">首页</div>
        <div
          v-for="(item,index) in headList"
          :key="index"
          :class="childrenId==index ? 'active' : 'proserve'"
          @click="getDiv(item,index)"
        >{{item.name}}</div>
      </div>
    </div>
    <!-- 手机端头部 -->
    <div class="navIs" style="width:100%;">
      <navbar></navbar>
    </div>
    <!-- 页面内容 -->
    <div class="contain">
      <!-- 一层标题-->
      <div
        class="titleFloor"
        v-for="item in swiper"
        :key="item.iid"
        v-show="item.iid == childrenId"
      >
        <img :src="item.url"  class="left_img">
        <div class="right_bd">
          <h1>{{item.title}}</h1>
          <div>{{item.content}}</div>
        </div>
      </div>
      <!-- 二层list -->
      <div>
        <!-- 平台功能 -->
        <h3>
          <div class="titleBg">
            <span>平台功能</span>
          </div>
        </h3>
        <div class="platform">
          <div
            class="secondList"
            v-for="items in secondList"
            :key="items.ids"
            v-show="items.ids == childrenId"
          >
            <div class="items" v-for="(itemm,indexx) in items.twoId" :key="indexx">
              <img :src="itemm.url" class="itemImg">
              <span class="itemsTitle">{{itemm.title}}</span>
              <div class="itemsText">
                <span class="childTitle">{{itemm.title}}</span>
                {{itemm.content}}
              </div>
            </div>
          </div>
        </div>
        <!--平台功能 手机端-->
        <div class="function">
          <div style="display:flex;">
            <div
              class="mList"
              v-for="items in secondList"
              :key="items.ids"
              v-show="items.ids == childrenId"
            >
              <div class="mitems" v-for="(itemm,indexx) in items.twoId" :key="indexx">
                <img :src="itemm.url" class="mitemImg">
                <span class="mitemsTitle">{{itemm.title}}</span>
              </div>
            </div>
            <div
              class="mList"
              v-for="items in secondList"
              :key="items.ids"
              v-show="items.ids == childrenId"
            >
              <div class="mitems mitemsBg" v-for="(itemm,indexx) in items.twoId" :key="indexx">
                <div class="mitemsText">
                  <span class="mchildTitle">{{itemm.title}}</span>
                  {{itemm.content}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 平台特点 -->
        <h3>
          <div class="titleBg">
            <span>平台特点</span>
          </div>
        </h3>
        <div class="featureIs">
          <div
            v-for="itm in thirdList"
            :key="itm.idd"
            v-show="itm.idd == childrenId"
            style="margin:auto;position:relative"
          >
            <div class="centerImg">
              <img src="../assets/images/imgs/feature.png">
            </div>
            <div class="feature">
              <div v-for="(v,i) in itm.oneItem" :key="i">
                <div class="featureLeft">
                  <span>{{v.title}}</span>
                  <div class="itemLine">
                    <div class="bottle"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="feature">
              <div v-for="(v,i) in itm.twoItem" :key="i">
                <div class="featureRight">
                  <span>{{v.title}}</span>
                  <div class="itemLine">
                    <div class="bottle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 手机端 -->
        <div class="mobelFeature">
          <div
            v-for="itm in thirdList"
            :key="itm.idd"
            v-show="itm.idd == childrenId"
            style="position:relative; margin:4% 0 0 -35px;"
          >
            <div class="feature">
              <div v-for="(v,i) in itm.oneItem" :key="i" style="width:50%;">
                <div class="feaitem">
                  <span>{{v.title}}</span>
                </div>
              </div>
              <div v-for="(v,i) in itm.twoItem" :key="i" style="width:50%;">
                <div class="feaitem">
                  <span>{{v.title}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="foot">
      <div class="footImg">
        <img src="../assets/images/imgs/fbg.png" width="100%;">
      </div>
      <div class="footList">
        <ul>
          <li>
            <img src="../assets/images/imgs/foot1.png">
            <span>联系我们</span>
          </li>
          <li>
            <img src="../assets/images/imgs/foot2.png">
            <span>加入我们</span>
          </li>
          <li class="download">
            <img src="../assets/images/code.png" class="code">
            <img src="../assets/images/imgs/foot3.png">
            <span>下载</span>
          </li>
        </ul>
      </div>
      <p>中华人民共和国信息产业部备案：豫ICP备05024996号</p>
    </div>
    <!-- 手机端底部 -->
    <!-- 底部 -->
    <div class="footm">
      <div class="footmList">
        <ul>
          <li>
            <img src="../assets/images/imgs/foot1.png">
            <span>联系我们</span>
          </li>
          <li>
            <img src="../assets/images/imgs/foot2.png">
            <span>加入我们</span>
          </li>
          <li class="download">
            <img src="../assets/images/code.png" class="code">
            <img src="../assets/images/imgs/foot3.png">
            <span>下载</span>
          </li>
        </ul>
      </div>
      <div class="footmP">中华人民共和国信息产业部备案：豫ICP备05024996号</div>
    </div>
  </div>
</template>
<script>
import navbar from "../components/nav";
export default {
  data() {
    return {
      childrenId: "",
      // hover翻转
      hoverIndex: "",
      // 导航栏
      divIndex: "",
      // 平台功能
      itemsHover: true,
      headList: [
        {
          ind: 0,
          name: "RimDeep",
          url: "/spider"
        },
        {
          ind: 1,
          name: "追影者",
          url: "/spider"
        },
        {
          ind: 2,
          name: "承影",
          url: "/artificial"
        },
        {
          ind: 3,
          name: "玄灵神",
          url: "/god"
        },
        {
          ind: 4,
          name: "含光",
          url: "/light"
        },
        {
          ind: 5,
          name: "云追查",
          url: "/yzc"
        }
      ],
      // 头部轮播图
      swiper: [
        {
          iid: 2,
          url: require("../assets/images/imgs/artificial_hold.png"),
          title: "承影-人工分析平台",
          content:
            "承影人工分析平台主要针对域名、IP、APP、服务器镜像，人工进行大量的数据分析，提炼出对用户有价值的数据信息，并将这些数据信息以一种便于用户理解的可视化数据方式进行展示。"
        },
        {
          iid: 1,
          url: require("../assets/images/imgs/spider.png"),
          title: "追影者-数据分析平台",
          content:
            "追影者对用户搜索的域名或者IP进行分析，并对网站相关的敏感词进行分析，判断网站的安全情况。在信息时代快速发展的当下，旨在为广大用户提供一个安全的上网环境。"
        },
        {
          iid: 0,
          url: require("../assets/images/imgs/RimDeep.png"),
          title: "RimDeep-信息感知平台",
          content:
            "RimDeep致力于建立一个安全的网络环境，主要通过域名和IP，分析网站特征并使用标签算法技术进行分类来呈现汇总的信息。该系统提供数据同源关联分析、端口探测、信息情报收集等功能，实现提前感知、预防预警的作用。"
        },
        {
          iid: 3,
          url: require("../assets/images/imgs/god.png"),
          title: "玄灵神-人工智能平台",
          content:
            "玄灵神利用人工智能技术，对用户检索的网站进行深入的分析，智能提取有价值的数据信息，并对相关IP地址进行IP反查，根据用户提供的相关信息进行，结合特征库的数据信息进行用户画像。"
        },
        {
          iid: 4,
          url: require("../assets/images/imgs/intelligent.png"),
          title: "含光-快速检索平台",
          content:
            "含光通过检索域名、IP、哈希地址，依托大数据库快速进行数据分析，将分析结果加以汇总和整理，以一种简单而又直观的方式呈现给用户，方便用户理解。含光平台旨在快速、准确的为用户提供有价值的数据信息。y"
        },
        {
          iid: 5,
          url: require("../assets/images/imgs/domain.png"),
          title: "云追查-域名/IP检索平台",
          content:
            "由于电子数据存在易变性和隐秘性，为适应不断变化的涉网犯罪手段，方便警方快速调证，云追查以快捷、高效、透明的特点，为警方的精准研判提供基础检索、数据分析、技术服务等技术支撑。"
        }
      ],
      // 二层平台功能
      secondList: [
        {
          ids: 2,
          twoId: [
            {
              url: require("../assets/images/imgs/retrieval.png"),
              title: "域名分析",
              content:
                "对检索的域名进行大量的数据分析，得到域名服务商、子域名、是否使用CDN节点、网站性质等信息。"
            },
            {
              url: require("../assets/images/imgs/IPshadow.png"),
              title: "IP分析",
              content:
                "对检索的IP地址进行大量的数据分析，得到开放端口、IP地理位置、资产信息等有关此IP的信息。"
            },
            {
              url: require("../assets/images/imgs/APP分析.png"),
              title: "动静分析",
              content:
                "对提交的APP进行动态/静态分析，得到此APP后台搭建的服务器信息、API接口信息、引入的第三方服务等相关信息。"
            },
            {
              url: require("../assets/images/imgs/13.png"),
              title: "映像研究",
              content:
                "运用互联网技术，对此服务器上安装的镜像和安装在备份服务器上的信息数据进行分析记录。"
            }
          ]
        },
        {
          ids: 1,
          twoId: [
            {
              url: require("../assets/images/imgs/4.png"),
              title: "检索分析",
              content:
                "根据大数据特征对域名、IP进行检索分析，得到真实的网站信息。"
            },
            {
              url: require("../assets/images/imgs/5.png"),
              title: "敏感判定",
              content:
                "对用户检索的网站进行敏感词提取，并对此网站进行标签分类。"
            },
            {
              url: require("../assets/images/imgs/6.png"),
              title: "截屏处理",
              content:
                "根据大数据特征，对网站的运行出现不正规的操作，进行截屏并汇报给数据库留存。"
            },
            {
              url: require("../assets/images/imgs/7.png"),
              title: "数据分析",
              content: "根据特征库对每一个网站进行分析，对网站进行标签分类。"
            },
            {
              url: require("../assets/images/imgs/8.png"),
              title: "数据聚合",
              content:
                "根据特征库对每个网站通过多维度的分析，收集每个网站的重要信息数据，并列入信息数据库。"
            },
            {
              url: require("../assets/images/imgs/9.png"),
              title: "画像定位",
              content:
                "通过记录网上聊天工具的用户人群，对用户人群进行分析，用于定位用户画像。"
            }
          ]
        },
        {
          ids: 0,
          twoId: [
            {
              url: require("../assets/images/imgs/标签分类.png"),
              title: "智能分类",
              content:
                "对检索的网站内容进行智能分析，根据分析数据，进行标签分类。"
            },
            {
              url: require("../assets/images/imgs/7.png"),
              title: "信息收集",
              content:
                "根据大数据信息对网站中所有信息进行收集，系统智能识别有用的信息数据并汇总，以一种直观的方式呈现给用户。"
            },
            {
              url: require("../assets/images/imgs/资产探测.png"),
              title: "资产探测",
              content:
                "追踪、掌握网络资产信息，了解目标网络内主机的操作系统类型、开放端口及其所运行的应用程序类型和版本信息。"
            }
          ]
        },
        {
          ids: 3,
          twoId: [
            {
              url: require("../assets/images/imgs/1.png"),
              title: "用户画像",
              content:
                "根据大数据特征库对平台所抓到的域名IP做多维度分析形成用户画像。根据网站主页面截图及文字提取内容进行提取研判分析。"
            },
            {
              url: require("../assets/images/imgs/2.png"),
              title: "案件研判",
              content:
                "根据特征库对每个网站的域名或IP打标签划分类，通过多维度分析，并以百分比的形式入库进行案件分析研判。"
            },
            {
              url: require("../assets/images/imgs/自我学习.png"),
              title: "自我学习",
              content:
                "通过大量样本自我学习、自我完善并建立更全面的模型库，黑样本数据将会不断自我学习完善智能模型库。"
            }
          ]
        },
        {
          ids: 4,
          twoId: [
            {
              url: require("../assets/images/imgs/10.png"),
              title: " IP检索",
              content:
                "对域名或者IP进行检索可得到注册商信息和购买人邮箱信息,也可以得到域名对应的真实IP。"
            },
            {
              url: require("../assets/images/imgs/retrieval.png"),
              title: "域名检索",
              content:
                "根据特征库对每个网站的域名或IP标签分类，通过多维度分析，并以百分比的形式入库进行案件分析研判。"
            },
            {
              url: require("../assets/images/imgs/区块链.png"),
              title: " 区块链",
              content:
                "对虚拟币交易的哈希地址进行追踪，获取其交易双方的真实信息、交易时间等信息。"
            }
          ]
        },
        {
          ids: 5,
          twoId: [
            {
              url: require("../assets/images/imgs/retrieval.png"),
              title: "域名检索",
              content:
                "对域名进行检索分析网站是否有CDN、域名注册商和使用者信息、子域名、网站性质等。"
            },
            {
              url: require("../assets/images/imgs/IPshadow.png"),
              title: " IP检索",
              content:
                "对IP进行检索可以分析出服务器提供商、精确的地理信息位置。"
            },
            {
              url: require("../assets/images/imgs/intelligence.png"),
              title: "智能分析",
              content:
                "人工灵活、全面的对检索的IP/域名，APP，服务器镜像分析进行大量的数据分析，并最终给客户一份信息数据更加全面的分析报告。"
            },
            {
              url: require("../assets/images/imgs/artificial.png"),
              title: "人工分析",
              content:
                "人工灵活、全面的对给出的IP或域名进行大量的数据分析，并最终给客户一份信息数据更加全面的分析报告。"
            },
            {
              url: require("../assets/images/imgs/clue.png"),
              title: "线索对接",
              content: "警方在取证调查时，可以通过平台进行和企业对接获取线索。"
            },
            {
              url: require("../assets/images/imgs/区块链.png"),
              title: " 区块链",
              content:
                "对虚拟币交易的哈希地址进行追踪，获取双方的交易信息、交易时间等信息。"
            }
          ]
        }
      ],
      // 三层平台特点
      thirdList: [
        {
          idd: 2,
          oneItem: [
            {
              title: "数据全面"
            },
            {
              title: "分析精准"
            }
          ],
          twoItem: [
            {
              title: "灵活分析"
            },
            {
              title: "恢复数据"
            }
          ]
        },
        {
          idd: 1,
          oneItem: [
            {
              title: "网站信息"
            },
            {
              title: "检索数据"
            }
          ],
          twoItem: [
            {
              title: "网站分析"
            },
            {
              title: "画像定位"
            }
          ]
        },
        {
          idd: 0,
          oneItem: [
            {
              title: "网络安全"
            },
            {
              title: "精准探测"
            }
          ],
          twoItem: [
            {
              title: "专业分析"
            },
            {
              title: "信息集中"
            }
          ]
        },
        {
          idd: 3,
          oneItem: [
            {
              title: "标签分类"
            },
            {
              title: "精准研判"
            }
          ],
          twoItem: [
            {
              title: "多维度分析"
            },
            {
              title: "智能模型库"
            }
          ]
        },
        {
          idd: 4,
          oneItem: [
            {
              title: "智能分析"
            },
            {
              title: "分析快速"
            }
          ],
          twoItem: [
            {
              title: "方便操作"
            },
            {
              title: "数据全面"
            }
          ]
        },
        {
          idd: 5,
          oneItem: [
            {
              title: "快速检索"
            },
            {
              title: "警企合作"
            }
          ],
          twoItem: [
            {
              title: "分析准确"
            },
            {
              title: "调证线索"
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.getId();
    this.getDiv();
  },
  components: {
    navbar
  },
  methods: {
    //跳转首页
    goHome() {
      this.$router.push("/");
    },
    // 获取选择Id
    getId() {
      this.childrenId = this.$store.state.id;
    },
    // 导航栏获取id
    getDiv(item, index) {
      this.divIndex = item.ind;
      if (index == 0) {
        this.$store.commit("getId", index);
        this.$router.push("/rimdeep");
      } else if (index == 1) {
        this.$store.commit("getId", index);
        this.$router.push("/spider");
      } else if (index == 2) {
        this.$store.commit("getId", index);
        this.$router.push("/artificial");
      } else if (index == 3) {
        this.$store.commit("getId", index);
        this.$router.push("/god");
      } else if (index == 4) {
        this.$store.commit("getId", index);
        this.$router.push("/light");
      } else if (index == 5) {
        this.$store.commit("getId", index);
        this.$router.push("/yzc");
      }
    }
  }
};
</script>
<style scoped>
.main {
  width: 100%;
  height: 100%;
  background-color: #000;
}
/*底部*/
.foot {
  position: relative;
  padding-bottom: 100px;
  text-align: center;
  margin-top:15%;
}
.footImg > img {
  position: absolute;
  left: 0;
  bottom: 0;
  /* z-index: -1; */
}
.footList {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  bottom: 170%;
}
.footList > ul {
  display: flex;
}
.footList > ul > li {
  margin: 0 80px;
}
.footList > ul > li > img {
  width: 80px;
  vertical-align: middle;
}
.footList > ul > li > span {
  display: block;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  color: #fff;
}
p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  bottom: 100%;
  font-size: 16px;
  color: #fff;
}
@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .footList {
    bottom: 70%;
  }
  p {
    bottom: 20%;
  }
}
</style>