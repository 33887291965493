import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/home.vue'
// 承影——人工分析
import Artificial from '../views/children/artificial.vue'
// 追影者——蜘蛛爬虫
import Spider from '../views/children/spider.vue'
// RimDeep——流量入口
import RimDeep from '../views/children/RimDeep.vue'
// 玄灵神——人工智能
import God from '../views/children/god.vue'
// 含光——深度分析
import Light from '../views/children/light.vue'
// 云追查——域名/IP检索软件
import YZC from '../views/children/yzc.vue'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [{
    path: '/',
    component: Index,
    // redirect: '/'    
  }, 
  {
    path: '/artificial',
    component: Artificial,  
  }, 
  {
    path: '/spider',
    component: Spider,  
  }, 
  {
    path: '/rimdeep',
    component: RimDeep,  
  }, 
  {
    path: '/god',
    component: God,  
  }, 
  {
    path: '/light',
    component: Light,  
  },
  {
    path: '/yzc',
    component: YZC,  
  }
]

})

// // 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
//   if (to.path == '/search' || to.path == '/') {
//     next()
//   } else{
//     let token = window.sessionStorage.getItem('token')
//     if(token==null || token == ''){
//       next('/')
//     }else {
//       next()
//     }
//   }
// })
export default router